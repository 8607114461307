import React, { useEffect, useState } from 'react';
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { NavLink, useLocation } from 'react-router-dom';
function HideMenu({ setIsMenuOpen }) {
  const { pathname } = useLocation();
  // console.log(pathname);
  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "auto", // Use 'auto' for immediate scrolling without smooth animation
    // });
    setIsMenuOpen(false);
  }, [pathname, setIsMenuOpen]);
  return null;
}

export default function HeaderV2() {

  const get_service_data = "https://www.sourcedesk.io/api/v1/get-service-list";
  const get_hire_category_data = "https://www.sourcedesk.io/api/v1/get-hire-category-list";

  const [serviceList, setServicedata] = useState([]);
  const [categoryList, setCategorydata] = useState([]);

  const [isFixed, setIsFixed] = useState(false);

  //service list api call
  const getserviceData = () => {
    axios
      .get(get_service_data)
      .then((res) => {
        
        setServicedata(res.data);
  
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

   //category list api call
  const getcategoryData = () => {
    axios
    .get(get_hire_category_data)
    .then((cat_res) => {
      
      setCategorydata(cat_res.data.category_array);

      console.log(cat_res.data.category_array);
    })
    .catch((err) => {
      console.log(err);
    });
    
  }

  useEffect(() => {
    getserviceData();
    getcategoryData();
  }, []);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };



  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = e => {
    const header = document.querySelector('.row-height');
    const nav = document.querySelector('.navigation');
    const megaMenu = document.querySelector('.mega-menu');
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop >= 150);
    if (scrollTop >= 150) {
      header.classList.add('fix-height');
      nav.classList.add('fix-height');
      megaMenu.classList.add('fix-height');
    } else {
      header.classList.remove('fix-height');
      nav.classList.remove('fix-height');
      megaMenu.classList.remove('fix-height');
      megaMenu.classList.remove('fix-height');
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function handleToggleMenu() {
    setIsMenuOpen(isMenuOpen => !isMenuOpen);
  }

  return (
    <>
      <HideMenu setIsMenuOpen={setIsMenuOpen} />
      <nav className="navigation">
        <div className="container-fluid">
          <div className="row align-items-center row-height">
            <div className="col d-flex justify-content-start">
              <div className="wrapper-start d-flex align-items-center">
                {/* <button className="menu-toggle" onClick={handleToggleMenu}>
                  <img src={window.location.origin + '/images/menu-toggle.png'} alt="" />
                </button> */}
                {/* onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"  */}
                <button className={`menu-toggle ${isMenuOpen?"opened":''}`} onClick={handleToggleMenu} aria-label="Main Menu">
  <svg width="100%" height="100%" viewBox="0 0 100 100">
    <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
    <path className="line line2" d="M 20,50 H 80" />
    <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
  </svg>
</button>
                <div className="logo">
                  <NavLink to="/">
                    <img src={window.location.origin + '/images/logo.png'} alt="" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col d-none d-md-flex justify-content-end">
              <div className="wrapper-end d-flex align-items-center">
                <NavLink to="/schedule-a-call" className="navbar-btn__primary">
                  Hire Developers
                </NavLink>
                <NavLink to="/login" className="navbar-btn__secondary">
                  Login
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className={`mega-menu ${isMenuOpen ? 'show' : ''} ${isFixed ? 'fix-height' : ''}`}>
          <div className="container-fluid">
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row>
                <Col md={6} lg={4}>
                  <Nav variant="pills" className="flex-column">
                    <div className="headers">Explore</div>
                    <Nav.Item>
                      <Nav.Link eventKey="0">Who We Are</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="1">Services</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">Hire Our Genius Mind</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink to={'/pricing'} className={'nav-link'}>
                        Pricing
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink to={'/apply-as-talent'} className={'nav-link'}>
                        Apply for Jobs
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={6} lg={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="0">
                      <div className="row">
                        <div className="col col-lg-7">
                          <div className="headers">Who We Are</div>
                          <ul className="menu">
                            <li>
                              <NavLink to="/" onClick={e => e.preventDefault()}>
                                Our Company
                              </NavLink>
                              <div className={`grid`}>
                                <div className="grid-inner">
                                  <ul className="menu-dropdown">
                                    <li>
                                      <NavLink to="/about-us">About Us</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/events">Events</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/life-at-sdg">Life at SDG</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/our-team">Our Team</NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li>
                              <NavLink to="/" onClick={e => e.preventDefault()}>
                                Why SDG
                              </NavLink>
                              <div className={`grid`}>
                                <div className="grid-inner">
                                  <ul className="menu-dropdown">
                                    <li>
                                      <NavLink to="/how-it-works">How It Works</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/distinct-vetting-process">Distinct Vetting Process</NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li>
                              <NavLink to="/" onClick={e => e.preventDefault()}>
                                Knowledge Centre
                              </NavLink>
                              <div className={`grid`}>
                                <div className="grid-inner">
                                  <ul className="menu-dropdown">
                                    <li>
                                      <NavLink to="/portfolio">Portfolio</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/customer-review">Customer Stories</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/blog">Blogs</NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="ratings-row">
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/clutch.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.8</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Clutch</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/glassdoor.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.5</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Glassdoor</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/google.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.6</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Google</div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-5">
                          <div className="tab-img">
                            <img src={window.location.origin + '/images/tab-graphic-services.png'} alt="" />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="1">
                      {/* <div className="container"> */}
                      <div className="row">
                        <div className="col col-lg-7">
                          <div className="headers">Services</div>
                          
                          <ul className="menu">
                          {serviceList.map((el, i) => (
                            <li>
                              <NavLink to={`/services/${el.service_slug}`}>{el.service_title}</NavLink>
                              <div className={`grid`}>
                                <div className="grid-inner">
                                {el.sub_service_array.length>0 ? 
                                  <ul className="menu-dropdown">
                                    {el.sub_service_array.map((sb, j) => (
                                    <li>
                                      <NavLink to={`/services/${sb.sub_service_slug}`}>{sb.sub_service_title}</NavLink>
                                    </li>
                                    ))}
                                    
                                  </ul>:<div></div>}
                                </div>
                              </div>
                            </li>

                          ))}
                           

                          </ul>

                          <div className="ratings-row">
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/clutch.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.8</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Clutch</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/glassdoor.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.5</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Glassdoor</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/google.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.6</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Google</div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-5">
                          <div className="tab-img">
                            <img src={window.location.origin + '/images/tab-graphic-services.png'} alt="" />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="row">
                        <div className="col col-lg-7">
                          <div className="headers">Hire Our Genius Mind</div>
                          <ul className="menu">
                          {categoryList.map((el2, j) => (
                            <li>
                              <NavLink to={`/hire-our-genius-mind/${el2.category_slug}`}>{el2.category_title}</NavLink>

                              <div className={`grid`}>
                                <div className="grid-inner">
                                {el2.sub_category_array.length>0 ?
                                  <ul className="menu-dropdown">
                                    {el2.sub_category_array.map((sb_c, k) => (
                                    <li>
                                      <NavLink to={`/hire-our-genius-mind/${sb_c.sub_category_slug}`}>{sb_c.sub_category_title}</NavLink>
                                    </li>
                                    ))}
                                  </ul>:<div></div>}
                                </div>
                              </div>

                            </li>
                             ))}
                          </ul>
                          <div className="ratings-row">
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/clutch.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.8</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Clutch</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/glassdoor.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.5</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Glassdoor</div>
                                </div>
                              </div>
                            </NavLink>
                            <NavLink to="/" onClick={e => e.preventDefault()}>
                              <div className="rating">
                                <div className="img-wrapper">
                                  <img src={window.location.origin + '/images/google.png'} alt="" />
                                </div>
                                <div className="content-wrapper">
                                  <div className="rated">
                                    <strong>4.6</strong>
                                    <span>
                                      <img src={window.location.origin + '/images/star.png'} alt="" />
                                    </span>
                                  </div>
                                  <div className="rated-on">Google</div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-5">
                          <div className="tab-img">
                            <img src={window.location.origin + '/images/tab-graphic-services.png'} alt="" />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        <div className={`mobile-menu ${isMenuOpen ? 'show' : ''} ${isFixed ? 'fix-height' : ''}`}>
          <div className="container-fluid">
            <ul className="i-list">
              <li>
                <div className="text">
                  Who We Are<button className="arr-down"></button>
                </div>
                <div className="grid">
                  <div className="grid-inner">
                    <ul className="ii-list">
                      <li>
                        <div className="text">
                          <NavLink to="/our-company" onClick={e => e.preventDefault()}>
                            Our Company
                          </NavLink>
                          <button className="arr-down"></button>
                        </div>
                        <div className="grid">
                          <div className="grid-inner">
                            <ul className="iii-list">
                              <li>
                                <NavLink to="/about-us">About Us</NavLink>
                              </li>
                              <li>
                                <NavLink to="/events">Events</NavLink>
                              </li>
                              <li>
                                <NavLink to="/life-at-sdg">Life at SDG</NavLink>
                              </li>
                              <li>
                                <NavLink to="/our-team">Our Team</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <NavLink to="/" onClick={e => e.preventDefault()}>
                            Why SDG
                          </NavLink>
                          <button className="arr-down"></button>
                        </div>
                        <div className="grid">
                          <div className="grid-inner">
                            <ul className="iii-list">
                              <li>
                                <NavLink to="/how-it-works">How It Works</NavLink>
                              </li>
                              <li>
                                <NavLink to="/distinct-vetting-process">Distinct Vetting Process</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <NavLink to="/knowledge-centre">Knowledge Centre</NavLink>
                          <button className="arr-down"></button>
                        </div>
                        <div className="grid">
                          <div className="grid-inner">
                            <ul className="iii-list">
                              <li>
                                <NavLink to="/portfolio">Portfolio</NavLink>
                              </li>
                              <li>
                                <NavLink to="/customer-review">Customer Stories</NavLink>
                              </li>
                              <li>
                                <NavLink to="/blog">Blogs</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="text">
                  Services <button className="arr-down"></button>
                </div>
                <div className="grid">
                  <div className="grid-inner">
                    <ul className="ii-list">
                    {serviceList.map((el, i) => (
                      <li>
                        <div className="text">
                        <NavLink to={`/services/${el.service_slug}`}>{el.service_title}</NavLink>
                        {el.sub_service_array.length>0 ? <button className="arr-down"></button>: ""}
                        </div>
                        {el.sub_service_array.length>0 ?<div className="grid">
                          <div className="grid-inner">
                            <ul className="iii-list">
                            {el.sub_service_array.map((sb, j) => (
                              <li>
                                <NavLink to={`/services/${sb.sub_service_slug}`}>{sb.sub_service_title}</NavLink>
                              </li>
                               ))}
                             
                            </ul>
                          </div>
                          
                        </div>: ""}
                        
                      </li>
                      ))}

                      {/* <li>
                        <NavLink to="/custom-software-development">Custom Software Development</NavLink>
                      </li> */}
                    
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="text">
                  Hire Our Genius Mind <button className="arr-down"></button>
                </div>
                <div className="grid">
                  <div className="grid-inner">
                    <ul className="ii-list">
                    {categoryList.map((el3, x) => (
                      <li>
                        <div className="text">
                        <NavLink to={`/hire-our-genius-mind/${el3.category_slug}`}>{el3.category_title}</NavLink>
                          
                        {el3.sub_category_array.length>0 ?<button className="arr-down"></button>: ""}
                        </div>
                        {el3.sub_category_array.length>0 ?
                        <div className="grid">
                          <div className="grid-inner">
                            <ul className="iii-list">
                            {el3.sub_category_array.map((sb_c2, y) => (
                              <li>
                              <NavLink to={`/hire-our-genius-mind/${sb_c2.sub_category_slug}`}>{sb_c2.sub_category_title}</NavLink>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>
                        : ""}
                      </li>
                    ))}
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/apply-as-talent">Apply for Jobs</NavLink>
              </li>
              <li>
                <NavLink to="/login">Login</NavLink>
              </li>
            </ul>
          </div>
          <div className="container-fluid mt-auto">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <NavLink to="/schedule-a-call" className="navbar-btn__primary">
                  Hire Developers
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
