import { Helmet } from "react-helmet";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";

export default function SocialResponsibility() {
  return <>
  <Helmet>
  <title>Cultivating a Culture of Impact |  Sourcedesk Commitment to Social Responsibility</title>
<meta name="description" content="Join Sourcedesk in a purpose-driven journey: Empower lives, foster community, and prioritize well-being. Elevate impact through social responsibility!" />
{/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 
  </Helmet>
  <InnerBanner InnBanner="images/scal_rspnblty_banner.png"
  bgRepeat=""
  bgColor="#fafbfd"
  BnrRightImg="images/scal_rspnblty_banner_img.png"
  TitleInn={[<span>Committed to Lasting Societal Impact Through Community Collaboration</span>]}
  ParaInn="Join Our Mission to Ignite Change Through Innovative Programs and Initiatives"
  priCTALink="" priCTATitle="" secCTALink={""} secCTATitle={""} />
  <TrustedSlider defaultTitle={"Trusted By"} />
  <section className="full_width USP_bottom_sec pursing_mission">
      <div className="container">
          <div className="row">
              <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 USP_bottom_image"> <img src="images/scal_pm_img.png" alt="img" /> </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 USP_bottom_text">
                  <div className="text_controller">
                      <h2><span>Empowering Tomorrow</span></h2>
                      <h3>Committed to Purposeful Leadership.</h3>
                      <p>Through our Corporate Social Responsibility (CSR) initiatives, we tirelessly extend education and assistance to enrich the lives of the less privileged. We are dedicated to the well-being of our society by contributing to the development of a dynamic and vibrant community. By equipping individuals with the right skills and knowledge, we aim to empower them to unlock their full potential and create a brighter future. </p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section className="full_width our_corporate fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Creating Lasting Impact<br /><span>Committed to Supporting Orphanages and Empowering Girls</span></h2>
              <hr />
              <p>At Sourcedesk Global, our CSR reflects our commitment as a community-conscious, socially responsible, and values-based organization, actively contributing to our local community's well-being.</p>
          </div>
          <div className="row">
              <div className="col-12 col-md-7 col-sm-7 col-xl-7 our_corporate_left">
                  <div className="text_controller">
                      <p><strong>Our Partnership with Jyoti Sarup Kanya Asra</strong></p>
                      <p>As part of our CSR initiative, we have forged a partnership with Jyoti Sarup Kanya Asra, a non-governmental organization (NGO) located in Kharar, Punjab (India). Established with the noble purpose of aiding less fortunate and orphaned girls, our organization ensures equal educational opportunities. Donations support vital work, enabling girls to grow up in good health and empowering them for a better life.</p>
                      <p>Our pledge is to actively contribute to nourishing and building the future of these girls. For every talent we hire, we cover the full cost of one child's food, lodging, clothing, and education. With this, we aim to enhance their lives by providing access to education, quality living conditions, and proper nutrition. We are dedicated to creating a positive change in the lives of these deserving children and strive to foster an environment where they can thrive and realize their full potential. </p>
                  </div>
              </div>
              <div className="col-12 col-md-5 col-sm-5 col-xl-5"><img src="images/scal_corporate.png" alt="" /></div>
          </div>
      </div>
    </section>
    <section className="full_width pricing_sec Clients_Communities fix_padding meet_the_people">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Partners in Progress: Empowering Clients and Communities</h2>
              <hr className="hr_black_bg" />
              <p>At Sourcedesk Global, we hold both our clients and the communities they serve in high regard. Recognizing the link between client success and community well-being, we collaborate closely to strengthen core competencies and optimize operations. Our commitment extends beyond providing exceptional solutions; we actively engage with clients to identify sustainable growth areas, tailoring strategies for enhanced efficiency.</p>
          </div>
      </div>
    </section>
    <section className="full_width fix_padding grnn_initiatives pursing_mission">
      <div className="container">
          <div className="text_controller">
              <h2><span>Preserving Nature</span></h2>
              <h3>Our Pursuit of Environmental Sustainability </h3>
              <hr />
              <p>At Sourcedesk Global, we acknowledge our responsibility as global citizens to protect and preserve the environment. This commitment to environmental sustainability propels us to proactively implement various green initiatives, including:</p>
              <ul>
                  <li>
                      <div className="grnn_numeric">1</div>
                      <div className="grnn_text"><strong>Enhancing Energy Efficiency – </strong> We continually adopt and explore innovative methods to reduce energy consumption in our operations. We invest in energy-efficient technologies and promote responsible energy usage to contribute to a greener future.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">2</div>
                      <div className="grnn_text"><strong>Empowering Carbon Footprint Reduction –</strong> We help our employees and staff understand and limit their personal carbon footprint. With various awareness campaigns and training programs, we encourage the responsible consumption of resources and waste management. </div>
                  </li>
                  <li>
                      <div className="grnn_numeric">3</div>
                      <div className="grnn_text"><strong>Cultivating an Environmental Mindset –</strong> We are dedicated to cultivating an environmentally-conscious mindset within our organization. Through internal communications, engagement activities, and educational programs, we inspire our employees to make environmentally responsible choices, both at work and in their personal lives.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">4</div>
                      <div className="grnn_text"><strong>Embracing Sustainable Alternatives –</strong> We actively seek and implement sustainable alternatives to air conditioning, personal transportation, and lighting, among other sources of energy usage. Embracing eco-friendly alternatives, we aim to minimize our environmental impact and promote greener practices within our organization.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">5</div>
                      <div className="grnn_text"><strong>Promoting Tree Planting initiatives –</strong> We recognize the important role of trees in maintaining a healthy ecosystem and actively participate in tree planting initiatives. By planting trees, we contribute to the betterment of the environment and inspire our employees and clients to join us in this endeavour. </div>
                  </li>
              </ul>
          </div>
      </div>
    </section>
    <section className="full_width fix_padding grnn_initiatives pursing_mission pursing_mission_2nd">
      <div className="container">
          <div className="text_controller">
              <h2><span>Investing in Our Greatest Asset </span></h2>
              <h3> Nurturing Employee Well-Being</h3>
              <hr />
              <p>At Sourcedesk Global, we prioritize empowering our team and fostering a culture that values their well-being. Our commitment goes beyond words, ingrained in our corporate culture. We believe investing in the professional growth and satisfaction of our employees strengthens our workforce.</p>
              <ul>
                  <li>
                      <div className="grnn_numeric">1</div>
                      <div className="grnn_text"><strong>Promoting a Culture of Fairness and Openness -</strong> We value diversity for its fresh perspectives, innovative ideas, and meaningful collaboration in our global operations. As an equal opportunity employer, we create an inclusive work environment where every team member is valued, respected, and provided opportunities for growth and success.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">2</div>
                      <div className="grnn_text"><strong> Nurturing Professional Growth –</strong> We are committed to nurturing our employees’ professional growth through comprehensive training programs, continuous learning opportunities, and career development initiatives. Investing in their skills and knowledge equips them to thrive in their roles.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">3</div>
                      <div className="grnn_text"><strong>Prioritizing Employee Well-Being -</strong> We offer a range of wellness programs, flexible work arrangements, and supportive policies for a harmonious integration of personal and professional lives. Prioritizing well-being fosters an environment encouraging creativity, innovation, and long-term employee satisfaction.</div>
                  </li>
                  <li>
                      <div className="grnn_numeric">4</div>
                      <div className="grnn_text"><strong>Building a Strong and Motivated Workforce -</strong> Our commitment goes beyond immediate needs to ensure our team's long-term success and fulfillment. We understand that when our employees thrive, our organization thrives. Together, we create a positive and dynamic work environment that fuels our collective success.</div>
                  </li>
              </ul>
              <p>Join us on the journey to empower our team. Together, let's foster a culture of fairness, openness, and continuous growth, providing employees with the opportunities and support to excel and reach their full potential.</p>
          </div>
      </div>
    </section>
</>
}