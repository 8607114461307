import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function CurrentOpenings() {
  const get_all_openings_by_location = "https://www.sourcedesk.io/api/v1/get-all-openings-by-location";
  const get_all_openings = "https://www.sourcedesk.io/api/v1/get-all-openings";
  const [job_list, setjobList] = useState([]);
  const [all_job_list, setalljobList] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getallOpeningsbylocation = () => {
    axios.get(get_all_openings_by_location, {
      params: {
        location: id
      }
    })
      .then(function (response) {
     
          setjobList(response.data.job_list);
        
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallOpenings = () => {
    axios.get(get_all_openings)
      .then(function (response) {
        setalljobList(response.data.all_job_list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getallOpeningsbylocation();
  }, []);

  useEffect(() => {
    getallOpenings();
  }, []);


  return (
    <>
      <Helmet>
        <title>Current Openings</title>
        <meta name="description" content="Current Openings" />
      </Helmet>
      <InnerBanner
        InnBanner={`${window.location.origin}/images/current-openings-bg.jpg`}
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg={`${window.location.origin}/images/current-openings-right.png`}
        BnrLeftImg=""
        TitleInn={['Find all ', <br/>, <span>Current Openings</span>]}
        ParaInn=""
        priCTALink={''}
        priCTATitle={''}
        secCTALink={''}
        secCTATitle={''}
      />
      <section className="full_width fix_padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text_controller text_controller_center">
                <h2>
                  Current <span>Openings</span>
                </h2>
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-none d-lg-block accordion_def">
              <Openings job_listArray={job_list} all_job_listArray={all_job_list}/>
            </div>
            <div className="d-block d-lg-none accordion_def">
              <OpeningsMob job_listArray={job_list} all_job_listArray={all_job_list}/>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding" style={{ background: 'url(images/life-at-sdg-cta-bg-1.jpg) 50% 50% no-repeat rgb(27, 33, 45)' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="text_controller">
              <h2 className="text-center text-sm-end pe-sm-3 pe-lg-4 border-right text-white">
                <span>READY TO</span>
                <br /> LEARN More?
              </h2>
            </div>
            <p className="ps-sm-3 ps-lg-4 me-sm-5 text-white text-center text-sm-start">Talk to an expert from Sourcedesk Global team.</p>
            <NavLink className="cmn_btn_fill text-nowrap ms-sm-5 cmn_btn_fill_white me-0" to="/">
              Begin Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

const Openings = ({ job_listArray, all_job_listArray }) => {
  return (
    <Tabs defaultActiveKey="all" id="fill-tab-example" className="mb-3 justify-content-center" fill>

      <Tab eventKey="all" title="All Jobs">

      {all_job_listArray.map((row, k) => (
      <div className="d-flex job-listing">
          <div className="job-icon">
            <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
          </div>
          <div className="title me-2">
            <h3>{row.job_title}</h3>
            <p style={{ color: '#a2a2a2' }} className="text-nowrap">
              <i className="fa fa-briefcase" aria-hidden="true"></i> {row.job_required_experience}
            </p>
          </div>
          <div className="info d-flex align-items-center ms-auto">
            <div className="place text-nowrap">
              <i className="fa fa-map-marker me-2" aria-hidden="true"></i> {row.job_location}{' '}
            </div>
            <div className="posted-on text-nowrap">
              <i className="fa fa-calendar me-2" aria-hidden="true"></i> {row.job_opening_date}{' '}
            </div>
            <NavLink to={`/openings/${row.job_slug}`} className="cmn_btn_fill m-0 d-inline-block text-nowrap">
              View Details
            </NavLink>
          </div>
        </div>
        ))}

      

        {/* <div className="d-flex justify-content-center">
          <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
            Load More
          </NavLink>
        </div> */}

      </Tab>

      {job_listArray.map((item, i) => (
      <Tab eventKey={item.location_slug} title={`Openings in ${item.location_title}`}>

      {item.all_openings.map((res, j) => (
      <div className="d-flex job-listing">
          <div className="job-icon">
            <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
          </div>
          <div className="title me-2">
            <h3>{res.job_title}</h3>
            <p style={{ color: '#a2a2a2' }} className="text-nowrap">
              <i className="fa fa-briefcase" aria-hidden="true"></i> {res.job_required_experience}
            </p>
          </div>
          <div className="info d-flex align-items-center ms-auto">
            <div className="place text-nowrap">
              <i className="fa fa-map-marker me-2" aria-hidden="true"></i> {res.job_location}{' '}
            </div>
            <div className="posted-on text-nowrap">
              <i className="fa fa-calendar me-2" aria-hidden="true"></i> {res.job_opening_date}{' '}
            </div>
            <NavLink to={`/openings/${res.job_slug}`} className="cmn_btn_fill m-0 d-inline-block text-nowrap">
              View Details
            </NavLink>
          </div>
        </div>
        ))}

        {/* <div className="d-flex justify-content-center">
          <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
            Load More
          </NavLink>
        </div> */}

      </Tab>
      ))}
     

 

 
    </Tabs>
  );
}
const OpeningsMob = ({ job_listArray, all_job_listArray }) => {
  return (
    <Accordion defaultActiveKey="0">

      <Accordion.Item eventKey="0">
        <Accordion.Header>All Jobs</Accordion.Header>
        <Accordion.Body>
        {all_job_listArray.map((row2, k) => (
          <NavLink className="d-flex job-listing" to={`/openings/${row2.job_slug}`}>
            <div className="job-icon">
              <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
            </div>
            <div className="title me-2">
              <h3>{row2.job_title}</h3>
              <p style={{ color: '#a2a2a2' }} className="experience">
                <i className="fa fa-briefcase" aria-hidden="true"></i> {row2.job_required_experience} <span className="mx-1">|</span> <i className="fa fa-map-marker me-2" aria-hidden="true"></i> {row2.job_location}{' '}
                <span className="mx-1">|</span> <i className="fa fa-calendar me-2" aria-hidden="true"></i> {row2.job_opening_date}
              </p>
            </div>
          </NavLink>
        ))}
        
          {/*<div className="d-flex justify-content-center">
              <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
              Load More
              </NavLink>
            </div>*/}
        </Accordion.Body>
      </Accordion.Item>
      {job_listArray.map((res2, l) => (
      <Accordion.Item eventKey={l++}>
        <Accordion.Header>{res2.location_title}</Accordion.Header>
        <Accordion.Body>

        {res2.all_openings.map((row3, m) => (
          <NavLink className="d-flex job-listing" to={`/openings/${row3.job_slug}`}>
            <div className="job-icon">
              <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
            </div>
            <div className="title me-2">
              <h3>{row3.job_title}</h3>
              <p style={{ color: '#a2a2a2' }} className="experience">
                <i className="fa fa-briefcase" aria-hidden="true"></i> {row3.job_required_experience} <span className="mx-1">|</span> <i className="fa fa-map-marker me-2" aria-hidden="true"></i> {row3.job_location}{' '}
                <span className="mx-1">|</span> <i className="fa fa-calendar me-2" aria-hidden="true"></i> {row3.job_opening_date}
              </p>
            </div>
          </NavLink>
        ))}
        

        </Accordion.Body>
      </Accordion.Item>
      ))}
    </Accordion>
  
  );
}
