import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import { Helmet } from "react-helmet";

export default function Career() {
  return <>
  <Helmet>
  <title>Unlock Your Career Path with Sourcedesk: Explore Opportunities and Growth</title>
<meta name="description" content="Elevate your career with Sourcedesk. Unlock opportunities and navigate your path to success. Explore and discover the next chapter in your professional journey." />
{/* <!----  Head    -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />
  </Helmet>
  <InnerBanner InnBanner="images/career_banner.png"
  bgRepeat=""
  bgColor="#fafbfd"
  BnrRightImg="images/career_ban_right.png"
  TitleInn={[<span>Unlock Your Career Potential at SDG</span>,<br />,"Where Passion Meets Innovation"]}
  ParaInn="Begin Your Journey to Success with a Company that Values Your Growth"
  priCTALink="/apply-as-talent" priCTATitle="Elevate Your Career" secCTALink={""} secCTATitle={""} />
  <section className="full_width career_discover fix_padding">
      <div className="container">
          <div className="row">
              <div className="col-sm-6 col-md-6 pe-md-5 career_dis_img"> <img src="images/career_cont_left.png" alt="img" /> </div>
              <div className="col-sm-6 col-md-6 career_dis_cont">
                  <div className="text_controller text_big_controller">
                      <h2 className="mb-0"><span>Our Story: Identity and Values</span></h2>
                      <h3 className="mb-4">Empowering Global Talent and Collaboration Through Technology</h3>
                      <p>Overcoming geographical barriers no longer hinders personal, business, and economic growth. In today's transformed world, becoming a top-tier engineer or collaborating globally doesn't demand costly universities or physical offices. Technology has levelled the playing field, enabling remote learning and work. SDG seizes this momentum with the virtual talent pool platform, empowering individuals and companies. Our global mission aims to create opportunities worldwide, regardless of location.</p>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width blog_pgbk career_opening fix_padding">
      <div className="container">
          <div className="text_controller text_big_controller text_controller_center">
              <h2><span>Current Job Openings</span></h2>
              <p>(No.# of total openings will be displayed)</p>
          </div>
          <div className="pgbk_form">
              <form action="post">
                  <div className="row row-cols-md-3 row-cols-1">
                      <div className="col"> <select>
                              <option>Job Title</option>
                              <option>HR Recruiter / Talent and HR Specialist</option>
                              <option>Urgent Hiring – AI/ML Developer (WFH)</option>
                              <option>Senior Data Scientist- AI/ ML (WFH)</option>
                              <option>Urgent Hiring – AI Chatbot Developer (WFH)</option>
                          </select> </div>
                      <div className="col"> <select>
                              <option>Location</option>
                              <option>Kolkata</option>
                              <option>Remote</option>
                          </select> </div>
                      <div className="col"> <input type="submit" value="Apply" className="cmn_btn_fill" /> </div>
                  </div>
              </form>
          </div>
      </div>
  </section>
  <section className="full_width career_all fix_padding">
      <div className="container">
          <div className="text_controller text_big_controller">
              <h2><span>All</span></h2>
          </div>
          <div className="row row-cols-1 row-cols-md-3">
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="https://www.sourcedeskglobal.com/job/hr-recruiter-talent-and-hr-specialist/" target="_blank" rel="noreferrer">HR Recruiter / Talent and HR Specialist</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Kolkata</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="https://www.sourcedeskglobal.com/job/urgent-hiring-ai-ml-developer/" target="_blank" rel="noreferrer">Urgent Hiring – AI/ML Developer (WFH)</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="https://www.sourcedeskglobal.com/job/senior-data-scientist-ai-ml-wfh/" target="_blank" rel="noreferrer">Senior Data Scientist- AI/ ML (WFH)</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="https://www.sourcedeskglobal.com/job/urgent-hiring-ai-chatbot-developer-wfh/" target="_blank" rel="noreferrer">Urgent Hiring – AI Chatbot Developer (WFH)</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="/" onClick={e=>e.preventDefault()}>Frontend Developer</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="/" onClick={e=>e.preventDefault()}>Backend Developer</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="/" onClick={e=>e.preventDefault()}>React Developer</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="/" onClick={e=>e.preventDefault()}>Node.js Developer</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
              <div className="col">
                  <div className="career_addrs">
                      <h3><a href="/" onClick={e=>e.preventDefault()}>WordPress Developer</a></h3>
                      <p><img src="images/career_location.png" alt="img" /> Remote - Global</p>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width apply_job_position career_benefit fix_padding">
      <div className="container">
          <div className="text_controller text_big_controller text_controller_center">
              <h2><span>Employee Benefits and Perks</span></h2>
          </div>
          <div className="table-responsive">
              <table className="table table-striped">
                  <thead>
                      <tr>
                          <th scope="col">Benefit</th>
                          <th scope="col">Description</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <th style={{width:"45%"}}>Medical Benefits</th>
                          <td style={{width:"55%"}}>Access to healthcare services and insurance</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Performance &amp; Review Bonus</th>
                          <td style={{width:"55%"}}>Rewarding high-performing employees with bonuses and recognition</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Prestigious Co-working Spaces</th>
                          <td style={{width:"55%"}}>Professional and productivity-enhancing workspaces</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Daily Meals and Other Perks</th>
                          <td style={{width:"55%"}}>Motivational perks, including daily meals</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Gym Membership</th>
                          <td style={{width:"55%"}}>Encouraging healthy living with gym access</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Onboarding and Support</th>
                          <td style={{width:"55%"}}>Guidance and support during onboarding and beyond</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Training and Development</th>
                          <td style={{width:"55%"}}>Opportunities for skill improvement and career advancement</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>ICT Opportunities to Work Abroad</th>
                          <td style={{width:"55%"}}>Work abroad options to gain global experience</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Computers &amp; Accessories</th>
                          <td style={{width:"55%"}}>Tools for efficient task performance</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Company Events, Perks</th>
                          <td style={{width:"55%"}}>Events and perks for a positive work environment</td>
                      </tr>
                      <tr>
                          <th style={{width:"45%"}}>Team Building Gateways and Awards</th>
                          <td style={{width:"55%"}}>Activities and awards to promote teamwork and recognize excellence</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center"> <NavLink to="/apply-as-talent" className="cmn_btn_fill">Apply for Jobs</NavLink> </div>
      </div>
  </section>
  <section className="full_width leadership_thaught cus_rev_slider career_slider_main fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Employee Experiences</h2>
              <hr />
          </div>
          <div className="devloper_slider slider"><button className="slick-prev slick-arrow" aria-label="Previous" type="button">Previous</button>
              <div className="vettng_video" style={{background: "url(images/vttng_prcs_bg.png)", backgroundPosition: "50% 50%", backgroundSize: "cover"}}> <a href="/">
                      <div className="vdo_btn"><img src="images/vdo_triangle.png" alt="Icon" /></div>
                  </a>
                  <h2>Video Title Here</h2>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width vettng_video_black pricing_pgbk customer_review_pgbk">
      <div className="container">
          <h2>Are you willing to construct a top-notch tech team? <br />If your answer is yes, let's get the ball rolling.</h2>
          <div className="cmn_btn_grp cmn_btn_grp_center"> <NavLink to="/how-it-works" className="cmn_btn_stroke">First, Show Me How It works</NavLink> <NavLink to="/schedule-a-call" className="cmn_btn_fill">Yes, Lets Gets Started</NavLink> </div>
      </div>
  </section>
  <section className="full_width career_discover fix_padding">
      <div className="container">
          <div className="row">
              <div className="col-sm-6 col-md-6 career_dis_cont">
                  <div className="text_controller text_big_controller">
                      <h2><span>Diversity &amp; Inclusion</span></h2>
                      <p>At SDG, we embrace and celebrate diversity as a fundamental strength that enriches our organization. We value the diverse talents, perspectives, and backgrounds of our people. Our inclusive culture welcomes individuals from all walks of life, fostering a positive work environment where everyone is treated with respect and dignity. Our commitment to everyday inclusion and equitable practices throughout the employee lifecycle ensures that diversity thrives within our company.</p>
                  </div>
                  <div className="cmn_btn_grp"> <NavLink to="/career" className="cmn_btn_fill">Explore Job Opportunities</NavLink> </div>
              </div>
              <div className="col-sm-6 col-md-6 ps-md-5 career_dis_img"> <img src="images/career_cont_right.png" alt="img" /> </div>
          </div>
      </div>
  </section>
  <section className="full_width career_life border_top  fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center text_big_controller">
              <h2><span>The Sourcedesk Global Experience</span></h2>
          </div>
          <div className="ft_grid">
              <ul>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_1.jpg" alt="img" />
                          <div className="vdo_btn"> <a href="/" onClick={e=>e.preventDefault()}><img src="images/vdo_triangle.png" alt="Icon" /></a></div>
                      </div>
                  </li>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_2.jpg" alt="img" /> </div>
                  </li>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_3.jpg" alt="img" /> </div>
                  </li>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_4.jpg" alt="img" /> </div>
                  </li>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_5.jpg" alt="img" /> </div>
                  </li>
                  <li>
                      <div className="ft_pro_box"> <img src="images/career_life_6.jpg" alt="img" /> </div>
                  </li>
              </ul>
          </div>
      </div>
  </section>
  <section class="full_width home_pgbk fix_padding">
      <div class="container">
          <div class="text_controller text_controller_center">
              <h2>Explore Opportunities</h2>
          </div>
          <div class="cmn_btn_grp cmn_btn_grp_center"> <a href="/" class="cmn_btn_fill" onClick={e=>e.preventDefault()}>Partner with Industry Leaders</a> </div>
      </div>
  </section>
  </>
}