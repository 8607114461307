import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import { Helmet } from "react-helmet";

export default function Internship() {
  return <>
      <Helmet>
        <title>Internship</title>
        <meta name="description" content="Internship" />
      </Helmet>
      <InnerBanner
        InnBanner="images/in-house-internship-bg.jpg"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/in-house-internship-right.png"
        BnrLeftImg=""
        TitleInn={["INTERNSHIP"]}
        ParaInn=""
        priCTALink={""}
        priCTATitle={""}
        secCTALink={""}
        secCTATitle={""} />
        <section className="fix_padding full_width">
          <div className="container">
            <div className="row">
              <div className="text_controller text_controller_center"><h2 className="mb-4">Find a <span>Career instead</span> of a Job</h2><p>Sourcedesk Global regularly provides internship opportunities to students looking to work in a dynamic and thriving business environment. We are seeking people looking to further develop their skills and that are eager to learn what it takes to be part of a successful team.
              We offer a flexible six-months internship opportunity that allows students to both work and continue their studies.</p><hr /></div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text_controller mb-4">
                  <h3><span>Who can apply?</span></h3>
                </div>
                <ul>
                  <li><p><i className="fa fa-star me-2" aria-hidden="true"></i> Any student or young professional between the ages of 18 to 30.</p></li>
                  <li><p><i className="fa fa-star me-2" aria-hidden="true"></i> Currently pursuing any vocational training, college or university program.</p></li>
                  <li><p><i className="fa fa-star me-2" aria-hidden="true"></i> Can work in Canada.</p></li>
                  <li><p><i className="fa fa-star me-2" aria-hidden="true"></i> Able to complete the duration of the course.</p></li>
                  <li><p><i className="fa fa-star me-2" aria-hidden="true"></i> Have a passion for digital marketing or accounting solutions.</p></li>
                </ul>
              </div>
              <div className="col-md-6 d-flex align-content-center justify-content-center">
                <img src="images/in-house-internship-who-can-apply.png" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="full_width fix_padding section-sdg-difference">
            <div className="container">
                <div className="text_controller">
                    <h2>Current <span>Open Internship</span> Opportunities</h2>
                    <hr className="hr_black_bg" />
                    <p>If you are an aspiring accountant, digital marketer or web developer, then we would like to meet you! Sourcedesk Global can help you add to your professional portfolio and expand your skill set with important projects to show future employers. Apply today and put your knowledge to practical use.</p>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <ul>
                            <li>
                                <h3 className="text_controller"><span>Bookkeeping Intern</span></h3>
                                <p>The person with this position will be responsible for closely working with the accountant on daily assignments and reviewing company budgets. This internship opportunity will help you acquire both technical and communication skills while enhancing your problem-solving abilities.</p>
                            </li>
                            <li>
                                <h3 className="text_controller"><span>Tax Intern</span></h3>
                                <p>This opportunity allows a unique hands-on perspective on our company’s practices that will help maximize your success in the future. The intern will receive exposure to multiple functions by working on real-life business operations.</p>
                            </li>
                            <li>
                                <h3 className="text_controller"><span>Web Development Intern</span></h3>
                                <p>Want to create a direct impact on the next generation web industry as a developer? With this internship program, you get to be a part of the highly-skilled development team and sharpen your web development skills.</p>
                            </li>
                            <li>
                                <h3 className="text_controller"><span>Digital Marketing Intern</span></h3>
                                <p>We are currently looking for our next star intern to join our digital marketing team. The intern will be responsible for executing digital marketing initiatives, maintain lead tracking, and developing and presenting creative marketing materials. If you have a solid passion for media & marketing, then this role is for you.</p>
                            </li>
                            <li>
                                <h3 className="text_controller"><span>Design Intern</span></h3>
                                <p>This position will go to a talented graphic designer looking to make a mark in the digital marketing field. The intern will get the opportunity to conceptualize and design brand templates, infographics and other corporate marketing materials.</p>
                            </li>
                            <li>
                                <h3 className="text_controller"><span>How to apply?</span></h3>
                                <p>Send us your CV along with a brief cover letter at <a href="mailto:career@sourcedeskglobal.com">career@sourcedeskglobal.com</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row"><div className="cmn_btn_grp cmn_btn_grp_center"><NavLink className="cmn_btn_fill cmn_btn_fill_white" to="/schedule-a-call">Apply for SDG internship Program</NavLink></div></div>
            </div>
        </section>
        <section className="fix_padding full_width" style={{background: "#fff url(images/in-house-internship-experience-bg.jpg) no-repeat 50% 50%"}}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="text_controller">
                  <h2>Life-Changing<br />
                  <span>Experiences</span></h2>
                  <hr />
                </div>
                <p className="mb-4">See what past alumni have to say about being on SDG Internship</p>
                <NavLink className="cmn_btn_fill d-inline-block" to="/schedule-a-call">JOIN US</NavLink>
              </div>
              <div className="col-md-6"><img src="images/in-house-internship-experience.png" alt="" /></div>
            </div>
          </div>
        </section>
        <section className="full_width fix_padding" style={{ background: '#1b212d url(images/life-at-sdg-cta-bg-1.jpg) no-repeat 50% 50%' }}>
          <div className="container">
            <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
              <div className="text_controller">
                <h2 className="text-center text-sm-end pe-sm-3 pe-lg-4 border-right text-white">
                  <span>READY TO </span><br />LEARN MORE?</h2>
              </div>
              <p className="ps-sm-3 ps-lg-4 me-sm-5 text-white text-center text-sm-start">Talk to an expert from Sourcedesk Global team.</p>
              <NavLink to="/" className="cmn_btn_fill text-nowrap ms-sm-5 cmn_btn_fill_white me-0">Apply Now</NavLink>
            </div>
          </div>
        </section>
        </>
}