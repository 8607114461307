import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function Openings() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const get_job_details = "https://www.sourcedesk.io/api/v1/get-job-details";
  const [jobDetails, setJobdetails] = useState([]);

  
  useEffect(() => {
    getJobdetails();
  }, [pathname]);

  const getJobdetails = () => {
    
    if(id){
      axios.get(get_job_details, {
        params: {
          job_slug: id
        }
      })
        .then(function (response) {
          console.log(response.data);
          if(response.data.job_details.length>0){
            setJobdetails(response.data.job_details);
          }
          else{
            navigate('/');
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    else{
      navigate('/');
    }
  };

if(jobDetails.length>0){


  return (
    <>
      <Helmet>
        <title>{jobDetails[0].meta_title}</title>
        <meta name="description" content={jobDetails[0].meta_description} />
      </Helmet>
      <InnerBanner
        InnBanner={`${window.location.origin}/images/find-your-job-banner.jpg`}
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg={`${window.location.origin}/images/find-your-job-right-banner.png`}
        BnrLeftImg=""
        TitleInn={['Find ', <span>Your Job</span>]}
        ParaInn=""
        priCTALink={''}
        priCTATitle={''}
        secCTALink={''}
        secCTATitle={''}
      />
      <section className="full_width fix_padding">
        <div className="container">
          <div className="row">
            <div className="text_controller text_controller_center">
              <h2 dangerouslySetInnerHTML={{__html:  jobDetails[0].job_title}}>
                
              </h2>
              <hr />
            </div>
          </div>
          <div className="job-card">
            <div className="row d-md-flex d-none">
              <div className="col">
                <div className="job-details-card">
                  <h3 className="job-title">{jobDetails[0].job_role}</h3>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="">
                      {/* <div className="company mb-4" style={{ color: '#3d5f6f' }}>
                        <NavLink to="/">Ralph Lauren</NavLink>{' '}
                        <i
                          style={{
                            color: '#ffc107',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                          className="fa fa-star"
                          aria-hidden="true"
                        ></i>{' '}
                        <span className="rating">4.1</span> | <span className="reviews">35</span> Reviews{' '}
                      </div> */}
                      <div className="job-info" style={{ color: '#3d5f6f' }}>
                        {' '}
                        <span className="text-nowrap">
                          <i className="fa fa-briefcase" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_required_experience}
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-inr" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_salary}
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-map-marker" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_location}
                        </span>{' '}
                      </div>
                    </div>
                    {jobDetails[0].is_apply=="Y" ? (
                    <div className="text-end">
                      <NavLink
                        to="https://hiring.sourcedesk.io/candidateportal"
                        className="cmn_btn_fill"
                        style={{
                          borderRadius: '999px',
                          margin: 0,
                          display: 'inline-block',
                        }}>
                        {' '}
                        Login to apply{' '}
                      </NavLink>
                    </div> ) : (<div></div>) }
                  </div>
                  <div className="row">
                    <div className="col">
                      <hr />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-content-center">
                    <div className="posted">
                      {' '}
                      <span className="text-nowrap">
                        Posted On: <strong>{jobDetails[0].job_opening_date}</strong>
                      </span>{' '}
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Opening: <strong>{jobDetails[0].job_no_vacancy}</strong>
                      </span>
                      {/* <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Applicants: <strong>0</strong>
                      </span> */}
                    </div>
                    <div className="">
                      <NavLink to="/current-openings">Send me jobs like this</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-md-none d-flex">
              <div className="col">
                <div className="job-details-card">
                  <h3 className="job-title" dangerouslySetInnerHTML={{__html:  jobDetails[0].job_role}}></h3>
                  <div className="row align-items-center">
                    <div className="col">
                      {/* <div className="company mb-4" style={{ color: '#3d5f6f' }}>
                        <NavLink to="/">Ralph Lauren</NavLink>{' '}
                        <i
                          style={{
                            color: '#ffc107',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                          className="fa fa-star"
                          aria-hidden="true"
                        ></i>{' '}
                        <span className="rating">4.1</span> | <span className="reviews">35</span> Reviews{' '}
                      </div> */}
                      <div className="job-info" style={{ color: '#3d5f6f' }}>
                        <span className="text-nowrap">
                          <i className="fa fa-briefcase" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_required_experience}
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-inr" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_salary}
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-map-marker" aria-hidden="true" style={{ marginRight: '8px' }}></i> {jobDetails[0].job_location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="posted">
                      {' '}
                      <span className="text-nowrap">
                        Posted On: <strong>{jobDetails[0].job_opening_date}</strong>
                      </span>{' '}
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Opening: <strong>{jobDetails[0].job_no_vacancy}</strong>
                      </span>
                      {/* <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Applicants: <strong>0</strong>
                      </span> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-2 mt-4">
                  {jobDetails[0].is_apply=="Y" ? (
                    <div className="">
                      <NavLink
                        to="/login"
                        className="cmn_btn_fill"
                        style={{
                          borderRadius: '999px',
                          margin: 0,
                          display: 'inline-block',
                        }}>
                        {' '}
                        Login to apply{' '}
                      </NavLink>
                    </div> ) : (<div></div>) }
                    <div className="">
                      <NavLink>Send me jobs like this</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Job Summary: </h4>
                </div>
                <p dangerouslySetInnerHTML={{__html:  jobDetails[0].job_summary}}></p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Key Responsibilities </h4>
                </div>
                <div dangerouslySetInnerHTML={{__html:  jobDetails[0].job_key_resposibility}}></div>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}> Required Skills and Qualifications: </h4>
                </div>
                <p dangerouslySetInnerHTML={{__html:  jobDetails[0].job_required_skills}}></p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Why Join Us: </h4>
                </div>
                <p dangerouslySetInnerHTML={{__html:  jobDetails[0].what_we_offer}}>
                </p>
              </div>
              <div className="job-details">
                <p>
                  {' '}
                  <strong>Role:</strong> {jobDetails[0].job_role}{' '}
                </p>
                <p>
                  {' '}
                  <strong>Job Type:</strong> {jobDetails[0].job_type}{' '}
                </p> 
                {/* <p>
                  {' '}
                  <strong>Industry Type:</strong> IT Services & Consulting{' '}
                </p> */}
                <p>
                  {' '}
                  <strong>Department:</strong> {jobDetails[0].job_department}{' '}
                </p>
                <p>
                  {' '}
                  <strong>Employment Type:</strong> {jobDetails[0].job_employment_type}{' '}
                </p>
                {/* <p>
                  {' '}
                  <strong>Role Category:</strong> Technology / IT{' '}
                </p> */}
                <p>
                  {' '}
                  <strong>Compensation:</strong> {jobDetails[0].job_salary}{' '}
                </p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>About Company: </h4>
                </div>
                <p  dangerouslySetInnerHTML={{__html:  jobDetails[0].about_company}}>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="developer-cta section-cta-horizontal fix_padding full_width"
        style={{ background: `url(${window.location.origin}/images/ai-ml-development-cta-horizontal-bg.jpg) center center / cover no-repeat`,}}>
        <div className="container">
          <div className="row align-items-center gy-4">
            <div className="col-sm-8">
              <h2 className="text_controller">Current Job Openings</h2>
            </div>
            <div className="col-sm-4">
              <NavLink className="cmn_btn_fill text-nowrap cmn_btn_fill_white" to="/current-openings">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
}
