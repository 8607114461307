import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import TrustedSlider from '../Components/TrustedSlider';
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";

function DeveloperProfileSlider({children}) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => <button></button>,
  };
  return (
    <Slider {...settings}>
      {children}
    </Slider>
  );
}

export default function Designer() {
  const { pathname } = useLocation();
  const get_category_details = "https://www.sourcedesk.io/api/v1/get-hire-category-details";
  const { id } = useParams();
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  const [categoryDetails, setcategoryDetails] = useState([]);
  const [categoryPlatform, setcategoryPlatform] = useState([]);
  const [catgeoryPricing, setcategoryPricing] = useState([]);
  const [categoryProfile, setcategoryProfile] = useState([]);
  const [categorySubcatlist, setcategorySubcatlist] = useState([]);

  useEffect(() => {

    getCategorydetails();
  }, [pathname]);

  //get category details api
  const getCategorydetails = () => {
 
    axios.get(get_category_details, {
      params: {
        category_slug: id
      }
    })
      .then(function (response) {
        console.log(response.data);
        if(response.data.result==1){

          if(response.data.category_array.length>0){

            setcategoryDetails(response.data.category_array);
            setcategoryPlatform(response.data.tools_platform_array);
            setcategoryPricing(response.data.pricing_array);
            setcategoryProfile(response.data.profile_array);
            setcategorySubcatlist(response.data.subcat_array);
  
          }
          else{
            navigate('/');
          }
        }
        else{
          navigate('/');
        }
       
        
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
if(categoryDetails.length>0){

if(categoryDetails[0].parent_category_id==0){


  return (
    <>
      <Helmet>
        <title>{categoryDetails[0].category_meta_title === null ? `Hire Pre-Vetted ${categoryDetails[0].category_title} | Junior to Senior Levels` : categoryDetails[0].category_meta_title}</title>
        <meta name="title" content={categoryDetails[0].category_meta_title === null ? `Hire Pre-Vetted ${categoryDetails[0].category_title} | Junior to Senior Levels` : categoryDetails[0].category_meta_title} />
        <meta
          name="description"
          content={categoryDetails[0].category_meta_desc === null ? `Are you looking for skilled remote ${categoryDetails[0].category_title}? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!` : categoryDetails[0].category_meta_desc}
        />{' '}
        {/*
          <!-- Canonical & hrefLang --> */}
        <link rel="canonical" href={currentUrl} />
        <link rel="alternate" hrefLang="x-default" href={currentUrl} />
        <link rel="alternate" hrefLang="en-US" href={currentUrl} />{' '}
        {/*
          <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta name="twitter:title" content={categoryDetails[0].category_meta_title === null ? `Hire Pre-Vetted ${categoryDetails[0].category_title} | Junior to Senior Levels` : categoryDetails[0].category_meta_title} />
        <meta
          name="twitter:description"
          content={categoryDetails[0].category_meta_desc === null ? `Are you looking for skilled remote ${categoryDetails[0].category_title}? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!` : categoryDetails[0].category_meta_desc}
        />
        <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />{' '}
        {/*
          <!-- Facebook Open Graph tags --> */}
        <meta property="og:url" content="https://www.sourcedesk.io/designers" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={categoryDetails[0].category_meta_title === null ? `Hire Pre-Vetted ${categoryDetails[0].category_title} | Junior to Senior Levels` : categoryDetails[0].category_meta_title} />
        <meta
          property="og:description"
          content={categoryDetails[0].category_meta_desc === null ? `Are you looking for skilled remote ${categoryDetails[0].category_title}? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!` : categoryDetails[0].category_meta_desc}
        />
        <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />{' '}
        {/*
          <!-- Additional Open Graph tags for better customization --> */}
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk Global" />
        <meta property="og:locale" content="en_US" />{' '}
        {/*
          <!-- Required meta tags --> */}{' '}
        {/*
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " />
          <meta name="robots" content="index,follow" /> */}{' '}
        <script type="application/ld+json">
          {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "@id": "https://www.sourcedesk.io/#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.sourcedesk.io/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": ${categoryDetails[0].category_meta_title === null ? `Hire Pre-Vetted ${categoryDetails[0].category_title} | Junior to Senior Levels` : categoryDetails[0].category_meta_title},
                    "item": ${currentUrl}
                  }
                ]
              }`}
        </script>
        <script type="application/ld+json">
          {`{
                "@context": "http://schema.org",
                "@type": "Review",
                "itemReviewed": {
                  "@type": "Product",
                  "name": ${categoryDetails[0].category_title},
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.5",
                    "bestRating": "5",
                    "worstRating": "1",
                    "ratingCount": "100" // Example: assuming 100 ratings contributed to the aggregate rating
                  }
                },
                "author": {
                  "@type": "Person",
                  "name": "SourceDesk"
                },
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "4.5",
                  "bestRating": "5",
                  "worstRating": "1"
                },
                "reviewBody": "Review Body"
              }`}
        </script>
      </Helmet>
      <InnerBanner
        InnBanner={categoryDetails[0].category_banner_bg_img}
        bgRepeat="no-repeat"
        bgColor="#f9fcfc"
        BnrRightImg={categoryDetails[0].category_banner_img}
        TitleInn={['Hire Expert Remote ', <span>{categoryDetails[0].category_title}</span>, ' with Sourcedesk']}
        ParaInn={`We connect you with full-time remote ${categoryDetails[0].category_title} who work in flexible time zones to meet your specific needs. With their years of experience, our ${categoryDetails[0].category_title} are experts at collaboration and timely delivery of their work.`}
        priCTALink="/schedule-a-call"
        priCTATitle={`Hire a ${categoryDetails[0].category_title}`}
        secCTALink={''}
        secCTATitle={''}
      />
      <TrustedSlider defaultTitle={'Trusted By'} />
      <section className="full_width fix_padding" style={{ background: '#fafbfd' }}>
        <div className="container">
          <div className="text_controller">
            <h2>
              {' '}
              Each <span>{categoryDetails[0].category_title}</span> Is Selected Based on the Following Parameters{' '}
            </h2>
            <hr />
          </div>
          <div className="row Human_img_bottom">
            <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
              <ul>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">Technically Sound</div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Problem-Solving <br /> Skills{' '}
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">Cultural Fit</div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Outstanding <br /> Communication Skills{' '}
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Time Management <br /> Skills{' '}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right">
              {' '}
              <img src={categoryDetails[0].category_second_img} alt={categoryDetails[0].category_title} />{' '}
            </div>
            <div className="cmn_btn_grp">
              {' '}
              <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
                {' '}
                Hire a {categoryDetails[0].category_title}{' '}
              </NavLink>{' '}
              <NavLink to="https://www.sourcedesk.io/apply-as-talent" className="cmn_btn_stroke">
                {' '}
                Apply Here{' '}
              </NavLink>{' '}
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 className="text-controller"> Why You Should Hire Dedicated {categoryDetails[0].category_title} Through Sourcedesk </h2>
            <hr />
          </div>
          <div className="row mt-40 align-items-center">
            <div className="col-md-6 col-sm-6">
              <div className="">
                {' '}
                <img src={categoryDetails[0].category_third_img} alt={categoryDetails[0].category_title} />{' '}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="stppng_accordian" id="accordionExample">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> Top Talent Selection</Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      We select {categoryDetails[0].category_title} through rigorous vetting of their technical skills. This process helps us provide you with the best talent to meet your needs.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Cost-Effective Solutions </Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      Hiring {categoryDetails[0].category_title} through Sourcedesk helps you save overhead costs without sacrificing quality. You'll have access to talented designers at competitive rates.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Global Prowess </Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      Our offshore {categoryDetails[0].category_title} come from diverse cultures and backgrounds. This brings with them a new perspective on projects and ensures that the outcome resonates with a wide
                      audience.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Flexible Management</Accordion.Header>
                    <Accordion.Body> Whether you need {categoryDetails[0].category_title} at an hourly rate or want them to be paid monthly, our flexible models will fit your business needs. </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      {catgeoryPricing.length>0 ? (
      <section className="full_width pricing_sec digital_mark_pricing fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Employ Dedicated {categoryDetails[0].category_title} at Competitive Price </h2>
            <hr className="hr_black_bg" />
          </div>
          <div className="row">

          {catgeoryPricing.map((prItem, j) => (
            <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
              <div className="prcng_sec_item">
                <div className="prcng_sec_item_icon">
                  <img src={prItem.pricing_icon} alt={prItem.pricing_title} />
                </div>
                <div className="prcng_sec_item_text">
                  <h3>{prItem.pricing_title}</h3>
                  <h4>{prItem.pricing_short_description}</h4>
                  <h2 dangerouslySetInnerHTML={{__html: prItem.pricing_amount}}></h2>
                  <span>{prItem.pricing_rate_unit}</span>
                </div>
              </div>
            </div>
            ))}
            
            
          </div>
          <div className="price_content">Save up to 66% cost as compared to candidates in the USA and Canada.</div>
        </div>
      </section>
       ): (
        <div></div>
    )}

{categoryProfile.length>0 ? (
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Our Pre-Vetted <span>{categoryDetails[0].category_title}</span> for You
            </h2>
            <hr />
          </div>
          <div className="row d-none d-sm-flex">
          {categoryProfile.map((profItem, k) => (
            // <div className="col-12 col-md-4 col-sm-4 col-xl-4">
            //   <div className="dvlprs_prfle_row">
            //     <div className="profile_author">
            //       {' '}
            //       <img src={profItem.photo} alt={profItem.name} />
            //       <h2>{profItem.name}</h2>
            //     </div>
            //     <div className="main_skill">
            //       <h4>MAIN SKILLS</h4>
            //       <ul>
            //       {profItem.skill_list.map((skill, l) => (
            //         <li>{skill.title}</li>
            //       ))}
            //       </ul>
            //     </div>
            //     <div>
            //       <h4>YEARS OF EXPERIENCE</h4>
            //       <h3>{profItem.total_yr_exp}</h3>
            //     </div>
            //     <div>
            //       <h4>LOCATION</h4>
            //       <h3>{profItem.location}</h3>
            //     </div>{' '}
            //     <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
            //       Hire Similar Profiles
            //     </NavLink>
            //   </div>
            // </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src={profItem.photo} alt={profItem.name} />
                  <h2>{profItem.name}</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                  {profItem.skill_list.map((skill, l) => (
                    <li>{skill.title}</li>
                  ))}
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>{profItem.total_yr_exp}</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>{profItem.location}</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
             ))}
          </div>
          <div className="d-block d-sm-none slider-profiles" id="cmn_slider_btn">
          <DeveloperProfileSlider>{categoryProfile.map((profItem, k) => (
            <div className="col-12 col-sm-6 col-md-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src={profItem.photo} alt={profItem.name} />
                  <h2>{profItem.name}</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                  {profItem.skill_list.map((skill, l) => (
                    <li>{skill.title}</li>
                  ))}
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>{profItem.total_yr_exp}</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>{profItem.location}</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
             ))}</DeveloperProfileSlider>
             </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
              Secure Top Talent
            </NavLink>
          </div>
        </div>
      </section>
       ): (
        <div></div>
    )}


      <section className="full_width fix_padding section-col-3" style={{ background: '#f9fcfc' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2> How the Vetting Process at Sourcedesk Works </h2>
            <hr />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>AI-Based Screening</h3>
                <p>We use advanced AI to pick the right designer for you.</p>
              </div>
              <div className="content-box">
                <h3>Technical Round</h3>
                <p>Selected candidates take online tests to assess their technical skills. </p>
              </div>
            </div>
            <div className="col-xl-4 d-none d-xl-flex align-items-center justify-content-center col-img">
              <img src={categoryDetails[0].category_fourth_img} alt={categoryDetails[0].category_title} />
            </div>
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>Requirement Sharing</h3>
                <p>We ask you to provide your requirements so we can find the designer you need. </p>
              </div>
              <div className="content-box">
                <h3>Hiring and Onboarding</h3>
                <p>After you choose your designer, Sourcedesk will handle their contract and onboarding on your behalf. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cmn_btn_grp cmn_btn_grp_center">
          <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
            Invest in Your Success
          </NavLink>
        </div>
      </section>

      {categorySubcatlist.length>0 ? (
      <section className="full_width vrtul_asstnt_devloper acc_finance_hire digital_mark_hire fix_padding">
        <div className="container">
          <div className="three_tab_top text_controller">
            <h2>
              Platform for Dedicated <span>{categoryDetails[0].category_title}</span>
            </h2>
            <hr />
          </div>
          <div className="sdg_devlopers_technologies">
            {/* <div> */}
              <h2>{categoryDetails[0].category_title}</h2>
              {/* <ul>
              {categorySubcatlist.map((sbCat, m) => (
                <li>
                  <NavLink to={`/hire-our-genius-mind/${sbCat.subcat_slug}`}><h3>{sbCat.subcat_title}</h3></NavLink>
                </li>
              ))}
                
              </ul> */}
              <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 gx-2 gy-2'>
              {categorySubcatlist.map((sbCat, m) => (
                // <li>
                <div className="col"><NavLink className="sd_dev_tech" to={`/hire-our-genius-mind/${sbCat.subcat_slug}`}>{sbCat.subcat_title}</NavLink></div>
                // </li>
              ))}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
      ): (
      <div></div>
    )}

    </>
  );
}
else{
  //subcategory part
  const FAQsData = [
    {
      faq: `How much does it cost to hire ${categoryDetails[0].category_title} from Sourcedesk?`,
      ans: `${categoryDetails[0].category_title} rates vary based on experience. We offer junior ${categoryDetails[0].category_title} $15 per hour and experienced ${categoryDetails[0].category_title} up to $40 per hour. We’re also open to discussing costs based on your specific project needs.`,
    },
    {
      faq: `How do you check the qualifications of a ${categoryDetails[0].category_title}?`,
      ans: `You can simply ask our ${categoryDetails[0].category_title} technical questions based on the design tools you want the project to incorporate. The more accurate their responses, the more confident you can be in their qualifications.`,
    },
    {
      faq: `Will the ${categoryDetails[0].category_title} be available in my time zone?`,
      ans: `Yes. Our dedicated resources will be available to work in any time zone. You can discuss the timeframe with the ${categoryDetails[0].category_title} to work accordingly.`,
    },
    {
      faq: `How fast can you hire a ${categoryDetails[0].category_title} for me?`,
      ans: `We will shortlist the candidate for you in 3 to 5 days. Then, you can directly interact with the ${categoryDetails[0].category_title} and make your selection. `,
    },
    {
      faq: `How do I hire a ${categoryDetails[0].category_title} from Sourcedesk?`,
      ans: 'You simply fill out the query form on our website and schedule a call with our representative. Share your requirements and we will find the best candidate for you. ',
    },
    {
      faq: `How do I communicate with your ${categoryDetails[0].category_title}?`,
      ans: 'Emails or messaging tools are some of the ways you can communicate directly with your designer. Similarly, Sourcedesk has internal tools that allow you to interact with them. ',
    },
  ];
  return (
    <>
      <Helmet>
        <title>{categoryDetails[0].category_meta_title === null ? `Hire Top-Tier ${categoryDetails[0].category_title} for Your Project | Sourcedesk`: categoryDetails[0].category_meta_title}</title>
        <meta name="title" content={categoryDetails[0].category_meta_title === null ? `Hire Top-Tier ${categoryDetails[0].category_title} for Your Project | Sourcedesk`: categoryDetails[0].category_meta_title} />
        <meta
          name="description"
          content={categoryDetails[0].category_meta_desc === null ? `Looking for top ${categoryDetails[0].category_title}? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs.`: categoryDetails[0].category_meta_desc}
        />
        {/*
      <!-- Canonical & hrefLang --> */}
        <link rel="canonical" href={currentUrl} />
        <link rel="alternate" hrefLang="x-default" href={currentUrl} />
        <link rel="alternate" hrefLang="en-US" href={currentUrl} />
        {/*
      <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta name="twitter:title" content={categoryDetails[0].category_meta_title === null ? `Hire Top-Tier ${categoryDetails[0].category_title} for Your Project | Sourcedesk`: categoryDetails[0].category_meta_title} />
        <meta
          name="twitter:description"
          content={categoryDetails[0].category_meta_desc === "" ? `Looking for top ${categoryDetails[0].category_title}? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs.`: categoryDetails[0].category_meta_desc}
        />
        <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
        {/*
      <!-- Facebook Open Graph tags --> */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={categoryDetails[0].category_meta_title === null ? `Hire Top-Tier ${categoryDetails[0].category_title} for Your Project | Sourcedesk`: categoryDetails[0].category_meta_title} />
        <meta
          property="og:description"
          content={categoryDetails[0].category_meta_desc === null ? `Looking for top ${categoryDetails[0].category_title}? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs.`: categoryDetails[0].category_meta_desc}
        />
        <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />

        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk Global" />
        <meta property="og:locale" content="en_US" />

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "@id": "https://www.sourcedesk.io/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.sourcedesk.io/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": ${categoryDetails[0].category_meta_title === null ? `Hire Top-Tier ${categoryDetails[0].category_title} for Your Project | Sourcedesk`: categoryDetails[0].category_meta_title},
                "item": ${currentUrl}
              }
            ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": ${categoryDetails[0].category_title},
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.5",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "100" // Example: assuming 100 ratings contributed to the aggregate rating
              }
            },
            "author": {
              "@type": "Person",
              "name": "SourceDesk"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Review Body"
          }`}
        </script>
      </Helmet>
      <InnerBanner
        InnBanner={categoryDetails[0].category_banner_bg_img}
        bgRepeat="no-repeat"
        bgColor="#f9fcfc"
        BnrRightImg={categoryDetails[0].category_banner_img}
        TitleInn={['Hire a Pre-Vetted ', <span>{categoryDetails[0].category_title}</span>, ' Within 3-5 Days']}
        ParaInn={`Are you in need of a dedicated ${categoryDetails[0].category_title}? Sourcedesk has the perfect solution. We provide thoroughly vetted ${categoryDetails[0].category_title} ready to bring your projects to life.`}
        priCTALink="/schedule-a-call"
        priCTATitle={`Hire a ${categoryDetails[0].category_title}`}
        secCTALink={''}
        secCTATitle={''}
      />
      <TrustedSlider defaultTitle={'Trusted By'} />
      <section className="full_width fix_padding section-why-sdg-is-best">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 className="text-controller"> Find Top-Tier {categoryDetails[0].category_title} Sourcedesk </h2>
            <hr />
            <p>
              At Sourcedesk, we offer expert offshore {categoryDetails[0].category_title} to drive your project's success. Our rigorous vetting process guarantees that only the most qualified professionals are
              selected to match your organization’s requirements.
            </p>
          </div>
          <div className="row mt-40 align-items-center">
            <div className="col-md-6 col-sm-6">
              <div className="">
                <img src={categoryDetails[0].category_second_img} alt={categoryDetails[0].category_title} />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="stppng_accordian" id="accordionExample">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Expert {categoryDetails[0].category_title}</Accordion.Header>
                    <Accordion.Body>
                      Our {categoryDetails[0].category_title} are dedicated professionals who work with passion and excellence. They integrate with your teams effortlessly and quickly align with your business goals.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Find {categoryDetails[0].category_title} from Around the Globe</Accordion.Header>
                    <Accordion.Body>
                      Our team of skilled {categoryDetails[0].category_title} is available worldwide, allowing us to accommodate your schedule regardless of your time zone. We connect you with professionals who can
                      adapt to your preferred working hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Successful Completion of Project</Accordion.Header>
                    <Accordion.Body>
                      Equipped with the latest technologies and tools, our remote candidates ensure the successful completion of your projects. With a 100% success rate, our experts consistently
                      deliver high-quality work on time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Thorough Vetting Process</Accordion.Header>
                    <Accordion.Body>
                      Before providing you with {categoryDetails[0].category_title}, we rigorously vet candidates to check their qualifications. We also carefully evaluate their technical and soft skills before hiring
                      them for your projects.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      {categoryProfile.length>0 ?(
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Some of Our Dedicated {categoryDetails[0].category_title}</h2>
            <hr/>
          </div>
          <div className="row d-sm-flex d-none">
          {categoryProfile.map((profItem, k) => ( 
          <div className="col-12 col-sm-6 col-md-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src={profItem.photo} alt={profItem.name} />
                  <h2>{profItem.name}</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                  {profItem.skill_list.map((skill, l) => (
                    <li>{skill.title}</li>
                  ))}
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>{profItem.total_yr_exp}</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>{profItem.location}</h3>
                </div>
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
          ))}
           
           
          </div>
          <div className="d-block d-sm-none slider-profiles" id="cmn_slider_btn">
          <DeveloperProfileSlider>{categoryProfile.map((profItem, k) => (
            <div className="col-12 col-sm-6 col-md-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src={profItem.photo} alt={profItem.name} />
                  <h2>{profItem.name}</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                  {profItem.skill_list.map((skill, l) => (
                    <li>{skill.title}</li>
                  ))}
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>{profItem.total_yr_exp}</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>{profItem.location}</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
             ))}</DeveloperProfileSlider>
             </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
              Build Your Team Now
            </NavLink>
          </div>
        </div>
      </section>
      ):(
        <div></div>
      )}

{categoryPlatform.length>0 ?(
      <section className="full_width fix_padding section-technical-expertise" style={{ background: '#f9fcfc' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Tools and Platforms Used by Our {categoryDetails[0].category_title}</h2>
            <hr />
          </div>
          <ul className="uniq_infrmtn_down">
          {categoryPlatform.map((plt, l) => ( 
            <li>
              <div className="uniq_infrmtn_left">
                <h3>{plt.cardTitle}</h3>
              </div>
              <div className="uniq_infrmtn_right">{plt.cardContent}</div>
            </li>
          ))}
          </ul>
          <div className="cmn_btn_grp cmn_btn_grp_center cmn_btn_exchange cmn_btn_wide">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke">
              Recruit Top {categoryDetails[0].category_title}
            </NavLink>
            <NavLink to="https://www.sourcedesk.io/hire-development-team" className="cmn_btn_fill">
              Build Your {categoryDetails[0].category_title} Team
            </NavLink>
          </div>
        </div>
      </section>
):(
  <div></div>
)}
      <section className="full_width bg_dark_blue fix_padding" style={{ background: '#1b212d' }}>
        <div className="container">
          <div className="text_controller">
            <h2>What Makes Sourcedesk Different?</h2>
            <hr />
            <p>We're more than just a virtual assistant. Here are some key points that set us apart from our competitors.</p>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 react_ft_cnt">
              <ul>
                <li>
                  <h3>AI-Aided Applicant Screening</h3>
                  <p>Sourcedesk uses advanced AI tools to simplify the screening process. Our expert managers further evaluate applicants for their skill qualifications.</p>
                </li>
                <li>
                  <h3>Affordable Rates</h3>
                  <p>
                    By choosing our services, you can reduce your recruitment and salary costs by two-thirds. We provide flexible pricing options, including monthly and hourly rates, for accessing
                    top-tier talent.
                  </p>
                </li>
                <li>
                  <h3>Access to Global Resources</h3>
                  <p>We connect you with Graphics Designers from different regions around the world. Their global experience adds a valuable dimension, enhancing creativity in every task.</p>
                </li>
                <li>
                  <h3>IT Support & Office Accessibility</h3>
                  <p>
                    We offer workspace for your chosen candidate, ensuring they can work comfortably. Our comprehensive IT support offers technical assistance, making it easy for your experts to work.
                  </p>
                </li>
                <li>
                  <h3>Flexible Contracts</h3>
                  <p>Whether you want to hire a remote Graphics Designers for a short or long tenure, we can hire professionals accordingly. Enjoy flexible contracts as per your needs.</p>
                </li>
                <li>
                  <h3>High Retention Rate</h3>
                  <p>Sourcedesk offers exceptional perks to candidates such as medical benefits, team lunches, corporate events and work flexibility to retain them longer. </p>
                </li>
                <li>
                  <h3>Dedicated Account Manager</h3>
                  <p>We provide a dedicated account manager to ensure your Graphics Designers meets your requirements. They oversee the process to guarantee smooth and efficient work.</p>
                </li>
                <li>
                  <h3>Easy Hiring Process</h3>
                  <p>Share your requirements with us, and we'll match you with the perfect professional artist. Our quick turnaround time ensures we select the right candidate within 3-5 days.</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="react_ft_img">
                <img src={categoryDetails[0].category_third_img} alt={categoryDetails[0].category_title} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-col-3" style={{ background: '#fff' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>How Talent Acquisition Works at Sourcedesk</h2>
            <hr />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>Share Your Requirements</h3>
                <p>Simply share your requirements, and we’ll match you with the best {categoryDetails[0].category_title} for your needs.</p>
              </div>
              <div className="content-box">
                <h3>Technical Assessment</h3>
                <p>Candidates undergo online tests to evaluate their technical skills.</p>
              </div>
            </div>
            <div className="col-xl-4 d-none d-xl-flex align-items-center justify-content-center col-img">
              <img src={categoryDetails[0].category_fourth_img} alt="" />
            </div>
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>AI-Powered Screening</h3>
                <p> We use advanced AI to select the perfect candidate for you.</p>
              </div>
              <div className="content-box">
                <h3>Hiring and Onboarding</h3>
                <p>Once you select your {categoryDetails[0].category_title}, we will take care of the contract and onboarding process.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cmn_btn_grp cmn_btn_grp_center">
          <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
            Find Your Desired {categoryDetails[0].category_title}
          </NavLink>
        </div>
      </section>
      <section className="developer-faqs full_width fix_padding developer-faqs__bg-1" style={{}}>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>FAQ</h2>
              <div className="text_controller">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="stppng_accordian">
                <Accordion defaultActiveKey={0}>
                  {FAQsData.map((e, i) => (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{e.faq}</Accordion.Header>
                      <Accordion.Body> {e.ans} </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width foot_gap pricing_pgbk fix_padding" style={{ background: '#1b212d url(images/cta-bg-sketch-artist.jpg) no-repeat 50% 50%' }}>
        <div className="container">
          <h2>Explore Sourcedesk's Top {categoryDetails[0].category_title} to Enhance Your Designing Projects</h2>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/" className="cmn_btn_stroke">
              Hire Pre-Vetted {categoryDetails[0].category_title}
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
  
}
}
}
