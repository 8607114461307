import { Helmet } from "react-helmet";

export default function Login() {
  return <>
  <Helmet>
  <title>Login - Sourcedesk</title>
    <meta name="description" content="Login - Sourcedesk" />
    {/* <!--  Head   -->
    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 

{/* <!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/vanilla-calendar.min.css">
<!-- <link rel="stylesheet" href="https://www.sourcedesk.io/public/css/dark.min.css"> -->
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/style.css">
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/responsive.css"> */}


    {/* <!-- Head end -->  */}
    </Helmet><section className="section-login full-width fix_padding" style={{background:"url(images/sdg-bg.jpg) #f9fcfc right bottom no-repeat "}}>
    <div className="container" style={{maxWidth: "1200px"}}>
      <div className="row">
        <div className="text_controller text-center">
          <h1>Welcome to <span>SDG</span></h1>
        </div>
      </div>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="login-wrapper">
            <div className="content-wrapper">
              <h2>Find the Perfect Candidates<br />for Your Needs</h2>
              <ul>
                <li><p>Follow simple steps to access our talent pool.</p></li>
                <li><p>Get top candidates to meet your project requirements.</p></li>
                <li><p>Our platform manages contracts, legalities, and onboarding for your chosen candidate.</p></li>
              </ul>
            </div>
            <a href="https://hiring.sourcedesk.io/clientportal" target="_blank" rel="noreferrer">Sign In As A Client</a>
          </div>
        </div>
        <div className="col-md-6">
          <div className="login-wrapper">
            <div className="content-wrapper">
              <h2>Discover the Perfect Job<br />for You</h2>
              <ul>
                <li><p>Work with global companies to elevate your career.</p></li>
                <li><p>Follow easy steps to get hired by leading companies.</p></li>
                <li><p>Enjoy flexible work options and competitive salary packages.</p></li>
              </ul>
            </div>
            <a href="https://hiring.sourcedesk.io/candidateportal" class="secondary" target="_blank" rel="noreferrer">Sign In As A Candidate</a>
          </div>
        </div>
      </div>
    </div>
  </section> </>
}